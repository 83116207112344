$pr1: #D3EFFE
$pr2: #DADAF9
$pr3: #D3EFFE
$pr4: #DADAF9
$pr5: #D3EFFE
$body: #fff
$theme: #27275E
$dark: #363636
$white: #fff
$light: #F5F5F5
$medium: #505050

$button: $theme
$intro: $dark

$blue: #40BAFF
$violet: #8E4A95
$darkblue: #3f436c
$pink: #EA4E98
$marine: #00AACB
$footer: #44727B
$grey: #cdcdcd

$basic: sans-serif
$inter: $basic

=placeholder
  &::-webkit-input-placeholder
    @content
  &:-moz-placeholder
    @content
  &::-moz-placeholder
    @content
  &:-ms-input-placeholder
    @content
