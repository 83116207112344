.title
  margin: 0
  padding: 0
  &-big
    color: $theme !important
    font-size: 3em
    font-weight: 700
    +v-sm
       font-size: 2.5em
    +v-xs
       font-size: 2em
    &.mini
      font-size: 2em
      margin-top: -20px !important
  &-intro
    color: $dark 
    font-size: 2em
    font-weight: 700
    +v-sm
      font-size: 1.7em
    &.desc
      margin-top: 24px
      margin-right: 16px
      color: $intro
      font-size: 1.4em
      font-weight: 400
      line-height: 1.5
      +v-sm
        margin: 0 !important
        padding: 0 !important
  &-sub
    font-size: 1.2em
    font-weight: 600
    line-height: 1.5
    +v-sm
       font-size: 1.1em
    +v-xs
       font-size: 1em
  &-part
    font-size: 1.05em
    font-weight: 500
    line-height: 1.5
    +v-xs
      font-size: .9em

footer
  a
    &:hover
      color: $dark !important
  .f
    &-text
      color: $footer
      font-size: 1em
      font-weight: 500
      line-height: 1.5

img
  +v-md
    max-width: 96% !important

.m-hide
  +v-md
    display: none !important

.m-tc
  +v-md
    text-align: center !important
